export function getCanonical(
  canonicalTail: string | undefined,
  slug: any
) {
  if (canonicalTail && canonicalTail !== undefined) {
    return process.env.NEXT_PUBLIC_BASE_URL + canonicalTail;
  } else if (slug && slug !== undefined && slug.length > 0) {
    const tail = slug.join('/');
    return `${ process.env.NEXT_PUBLIC_BASE_URL }/${ tail }`;
  } else {
    return `${ process.env.NEXT_PUBLIC_BASE_URL }`;
  }
}

export function getOpenGraphProp(seo: any) {
  if (seo?.openGraphImage) {
    return  {
      openGraph: {
        images: [
          {
            url: seo?.openGraphImage.asset.url,
          },
        ],
      },
    };
  }
}
