// TODO: Optimise URLSearchParams
import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@components/core/layout/Grid';
import Card from '@components/Card';
import Pagination from '@components/core/Pagination';
import Filters from '@components/Filters';
import Link from 'next/link';
import { useRouter } from 'next/router';

// Part before LookUp are keys and most be one of the domains
const lookUps = {
  kinderopvang: {
    BSO: 'Buitenschoolse opvang',
    KDV: 'Kinderdagverblijf',
    POV: 'Peuteropvang',
    ZJK: 'Zorg Jonge Kind',
  },
};

const getDistance = (lat1, lon1, lat2, lon2, unit) => {
  if ((lat1 == lat2) && (lon1 == lon2)) {
    return 0;
  }
  else {
    const radlat1 = Math.PI * lat1/180;
    const radlat2 = Math.PI * lat2/180;
    const theta = lon1-lon2;
    const radtheta = Math.PI * theta/180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180/Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit=='K') { dist = dist * 1.609344; }
    if (unit=='N') { dist = dist * 0.8684; }
    return dist;
  }
};

export default function Overview(props) {
  const domain = props.domain;
  const itemsPerPage = 12;
  const router = useRouter();
  const [ forceLoad, setForceLoad ] = useState(false);
  const [ selectedPage, setPage ] = useState(1);
  const [ selectedProductType, setProductType ] = useState(null);
  const [ selectedLocation, setLocation ] = useState({
    value: '',
    isValid: true,
    validState: { }, // key: type of validation, value: boolean
    showErrorMessage: false,
  });

  const [ buttonIsDisabled, setButtonIsDisabled ] = useState(true);
  const [ activateFilter, toggleActiveFilter ] = useState(false);

  useEffect(() => {
    setForceLoad(!forceLoad);
  }, [ router ]);

  const handleEmitLocation = (e) => {
    setLocation({
      value: e.target.value ,
      isValid: true,
      validState: { }, // key: type of validation, value: boolean
      showErrorMessage: false,
    });
    setButtonIsDisabled(false);
  };

  const getCurrentGeolocation = async(zipcodeValue) => {
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?key=${ process.env.NEXT_PUBLIC_GOOGLE_API_KEY }&address=${ zipcodeValue + ', Nederland' }`);
    const data = await response.json();

    if (data.status === 'OK') {
      const geometry = data.results[0].geometry.location;
      return {
        lat: geometry.lat,
        lng: geometry.lng,
      };
    }
  };

  /* Not for onload */
  const applyFilter = async() => {
    const searchParams = new URLSearchParams(window.location.search);

    // Set distance
    if (selectedLocation.value && selectedLocation.value.length > 0 && selectedLocation.isValid) {
      const currentGeolocation = await getCurrentGeolocation(selectedLocation.value);
      if (currentGeolocation) {
        for (const locationIndex in props.locations) {
          if (props.locations[locationIndex].zipcode !== null) {
            const distance = getDistance(
              props.locations[locationIndex].geoLocation?.lat,
              props.locations[locationIndex].geoLocation?.lng,
              currentGeolocation.lat,
              currentGeolocation.lng,
              'K'
            );
            props.locations[locationIndex].zipcode.distance = distance;
          }
        }
        searchParams.set('location', selectedLocation.value);
      } else {
        setLocation((prevState) => ({
          ...prevState,
          ...{ isValid: false },
          ...{ validState: {
            pattern: false,
          } },
          ...{ showErrorMessage: true },
        }));
      }
    } else {
      for (const locationIndex in props.locations) {
        props.locations[locationIndex].zipcode.distance = '';
      }
      setLocation({
        value: '',
        isValid: true,
        validState: { }, // key: type of validation, value: boolean
        showErrorMessage: false,
      });
      searchParams.delete('location');
    }

    // Set URL params for product type
    if ( selectedProductType && Array.isArray(selectedProductType) && selectedProductType.length !== Object.keys(lookUps[domain]).length) {
      const productTypeParams = selectedProductType.map((productTypeOption) => productTypeOption.value).join();
      searchParams.set('productType', productTypeParams);
    } else {
      searchParams.delete('productType');
    }

    if (selectedPage !== 1) {
      setPage(1);
    }

    searchParams.set('page', '1');

    const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
    history.pushState(null, '', newRelativePathQuery);
    toggleActiveFilter(!activateFilter);
    setButtonIsDisabled(true);
  };

  /**
   * On load:
   * Set states based on searchParams
   * Load Google service
   * Set zipcode array per location
   */
  useEffect(() => {
    // Set zipcode per location
    for (const location of props.locations) {
      location.zipcode = null;
      if (location.sectionBox ) {
        const sectionIndex = location.sectionBox.findIndex((section) => section._type === 'multiContactSection');
        // Has no section that holds product data
        if ( sectionIndex === -1 || !location.sectionBox[sectionIndex].products[0].contactDetails ) {
          continue;
        } else {
          location.zipcode = {
            code: location.sectionBox[sectionIndex].products[0].contactDetails.zipCode,
            distance: null, // Already set prop for google distance service relative to user zipcode
          };
        }
      } else {
        continue;
      }
    }

    // Set states based on searchParams
    if (window.location.search && window.location.search.length > 0) {
      const searchParams = new URLSearchParams(window.location.search);
      // Set page
      const pageParam = searchParams.get('page');
      if (pageParam && pageParam !== '1') {
        setPage(parseInt(pageParam));
      }

      // Set product type based on param
      const productTypeParams = searchParams.get('productType');
      if (productTypeParams && productTypeParams.length > 0) {
        const productTypeOptions = decodeURIComponent(productTypeParams).split(',')
          .map( (productTypeParam) => {
            return {
              label: lookUps[domain][productTypeParam],
              value: productTypeParam,
            };
          });
        setProductType(productTypeOptions);
      }

      // Set zipcode based on param
      const locationParams = searchParams.get('location');
      if (locationParams && locationParams.length > 0) {
        setLocation({
          value: locationParams,
          isValid: true,
          validState: { pattern: true }, // key: type of validation, value: boolean
          showErrorMessage: false,
        });
        const asyncSetDistance = async() => {
          const currentGeolocation = await getCurrentGeolocation(locationParams);
          if (currentGeolocation) {
            for (const locationIndex in props.locations) {
              const distance = getDistance(
                props.locations[locationIndex].geoLocation?.lat,
                props.locations[locationIndex].geoLocation?.lng,
                currentGeolocation.lat,
                currentGeolocation.lng,
                'K'
              );
              props.locations[locationIndex].zipcode.distance = distance;
            }
          } else {
            setLocation((prevState) => ({
              ...prevState,
              ...{ isValid: false },
              ...{ validState: {
                pattern: false,
              } },
              ...{ showErrorMessage: true },
            }));
          }
          toggleActiveFilter(!activateFilter);
          setButtonIsDisabled(true);
        };

        asyncSetDistance();
      } else {
        setLocation({
          value: '',
          isValid: false,
          validState: { }, // key: type of validation, value: boolean
          showErrorMessage: false,
        });
        toggleActiveFilter(!activateFilter);
        setButtonIsDisabled(true);
      }
    }

    return;
  }, [ forceLoad ]);

  const filteredLocations = useMemo(() => {
    const montrisLocations = props.locations.filter((location) => location.pageTitle.startsWith('Montris'));
    return props.locations
      .filter((location) => !location.pageTitle.startsWith('Montris'))
      .concat(montrisLocations)
      .filter((location) => { // Filter on selected product type
        const noProductTypeFilter =
        !selectedProductType || !Array.isArray(selectedProductType) ||
        selectedProductType.length === 0 || selectedProductType.length === Object.keys(lookUps[domain]).length;
        if (noProductTypeFilter) {
          return true;
        }

        // Has any section
        if (location.sectionBox ) {
          const sectionIndex = location.sectionBox.findIndex((section) => section._type === 'multiContactSection');
          // Has no section that holds product data
          if ( sectionIndex === -1) {
            return false;
          }
          // Has product data
          let hasSelectedProduct = false;
          for (const product of location.sectionBox[sectionIndex].products) {
            for (const productType of selectedProductType) {
              if (product.productName === productType.value) {
                hasSelectedProduct = true;
              }
            }
          }
          return hasSelectedProduct;
        } else {
          return false;
        }
      })
      .sort((a, b) => {
        if (a.zipcode && a.zipcode.distance ) {
          if (b.zipcode && b.zipcode.distance) {
            if (a.zipcode.distance < b.zipcode.distance) {
              return  -1;
            } else if (a.zipcode.distance > b.zipcode.distance) {
              return 1;
            } else {
              return 0;
            }
          } else {
            return -1;
          }
        } else {
          return 0;
        }
      });
  }, [ props.locations, activateFilter ]);

  /* Update page query without re-render */
  function selectPage(page: number) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', JSON.stringify(page));
    const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
    history.pushState(null, '', newRelativePathQuery);
    setPage(page);
  }

  return (
    <section className='pt-8 md:pt-16 xl:pt-24 pb-0'>
      {
        domain === 'kinderopvang' &&
        <>
          <Grid>
            <div className='col-span-12 text-heading-xs mb-4'>Filter op uw voorkeuren
              <span className='text-shade'> ({ filteredLocations.length } resulta{ filteredLocations.length > 1 ? 'ten' : 'at'})</span>
            </div>
          </Grid>
          <div className='mb-6 md:mb-8 xl:mb-12'>
            <Grid>
              <div className='col-span-12 flex flex-col lg:flex-row'>
                <Filters
                  position='overview'
                  options={ {
                    productTypes: lookUps[domain],
                    // cities: cityOptions,
                  } }
                  selectedProductType={ selectedProductType }
                  // selectedCity={ selectedCity }
                  selectedLocation={ selectedLocation }
                  // zipcode={ zipcode }
                  buttonIsDisabled={ buttonIsDisabled }
                  emit={ {
                    productType: setProductType,
                    // city: setCity,
                    location: handleEmitLocation,
                    applyFilter,
                    disableApplyButton: setButtonIsDisabled,
                  } }
                  buttonLabel='Pas filters toe'
                />
              </div>
            </Grid>
          </div>
        </>
      }
      <Grid>
        { filteredLocations
          .map((location) => {
            return (
              <div key={ location._id } className='col-span-12 md:col-span-6 xl:col-span-4 mb-6 md:mb-8 xl:mb-12'>
                <Link href={ `/${ domain }/locaties/${ location.slug.current }` } key={ location._id }>
                  <Card  location={ location } productLookUp={ lookUps[domain] } domain={ props.domain } />
                </Link>
              </div>
            );
          }).slice(Math.max(0, (selectedPage * itemsPerPage) -  itemsPerPage), selectedPage * itemsPerPage)}
        <Pagination
          dataLength={ filteredLocations.length }
          itemsPerPage={ itemsPerPage }
          currentPage={ selectedPage }
          setCurrentPage={ selectPage }
          className='col-span-6 col-start-4 mb-6 md:mb-8 xl:mb-12'
        />
      </Grid>
    </section>
  );
}
