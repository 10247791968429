import React, { useState, useEffect, useRef } from 'react';
import Icon from '@components/core/Icon';
import useMediaQuery from '@helpers/hooks/useMediaQuery';

const styling = {
  pagination: `
    flex
    items-center
    justify-center
  `,
  pagination__button: `
    w-12
    h-12
    flex
    items-center
    justify-center
    mx-1
    rounded-full
    text-shade-900
    text-body-lg
    text-body-lg--semibold
    hover:text-primary
  `,
  'pagination__button--disabled': `
    text-shade-400
    !pointer-events-none
  `,
  'pagination__button--active': `
    !text-primary
    !pointer-events-none
  `,
  'pagination__chevron': `
    flex
    items-center
    justify-center
    mx-1
    text-shade-900
    text-body-lg
    text-body-lg--semibold
    hover:text-primary
  `,
  'pagination__chevron--disabled': `
    text-shade-400
    !pointer-events-none
  `,
  'pagination__active-mobile': `
    text-primary
  `,
};

interface IPagination  {
  dataLength: number;
  itemsPerPage: number;
  currentPage: number;
  visibleRange?: number;
  className: string;
  setCurrentPage: (currentPage: number) => void;
}

export default function Pagination(props: IPagination) {
  const paginationRef = useRef<HTMLInputElement>(null);
  const [ totalPages, setTotalPages ] = useState(1);
  const [ visibleRange, setVisibleRange ] = useState(props.visibleRange || 1);
  const isMobile = useMediaQuery(786);

  // Run once
  useEffect(() => {
    const resizeHandler = () => {
      if (window.innerWidth < 500) {
        setVisibleRange(0);
      } else if (window.innerWidth < 700) {
        setVisibleRange(1);
      } else {
        setVisibleRange(1);
      }
    };

    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  const { dataLength, itemsPerPage, currentPage, setCurrentPage } = props;

  // TODO: Maybe get the location for creating relative URLs for pages?

  useEffect(() => {
    const totalPages = Math.ceil(dataLength / itemsPerPage) || 1;

    if (currentPage > totalPages) { // SHOULD NOT BE POSSIBLE
      setCurrentPage(totalPages);
    }

    setTotalPages(totalPages);
  }, [ dataLength, itemsPerPage, currentPage, setCurrentPage ]);

  const updatePage = (position: number) => {
    props.setCurrentPage(position);
    setTimeout(() => {
      window.scrollTo({ top: (paginationRef?.current?.parentElement?.parentElement?.getBoundingClientRect().top || 0) + window.pageYOffset - 120, behavior: 'smooth' });
    }, 60);
  };

  const createPages = () => {
    let startPosition = props.currentPage - visibleRange;
    let endPosition = props.currentPage + visibleRange;

    if (startPosition < 3) {
      endPosition += (3 - startPosition);
    }

    if (endPosition > totalPages - 2) {
      startPosition -= (endPosition - (totalPages - 2));
    }

    startPosition = (startPosition < 2) ? 2 : startPosition;
    endPosition = (endPosition > totalPages - 1) ? totalPages - 1 : endPosition;

    const totalVisible = endPosition - startPosition;

    const pages = [];

    // Arrow left (previous)
    pages.push(
      <button
        type='button'
        className={
          1 === props.currentPage
            ? `${ styling.pagination__chevron } ${ styling['pagination__chevron--disabled'] }`
            : `${ styling.pagination__chevron }`
        }
        key={ 'pagination-chevron-left' }
        onClick={ () => updatePage(props.currentPage - 1) }
      >
        <Icon name={ 'ChevronLeft' }  />
        <span className='ml-2'>Vorige</span>
      </button>
    );

    // Always render first and last page
    pages.push(
      <button
        type='button'
        className={
          1 === props.currentPage
            ? `${ styling.pagination__button } ${ styling['pagination__button--active'] }`
            : styling.pagination__button
        }
        key={ `pagination-${ 1 }` }
        onClick={ () => updatePage(1) }
      >
        1
      </button>
    );

    let pageNumber = startPosition;
    let pageCount = 0;

    while (pageNumber <= endPosition) {
      const currentNumber = pageNumber;

      if (pageCount === 0) {
        if (currentNumber > 3) {
          // Render dots ... before visible range
          pages.push(
            <button
              type='button'
              className={ styling.pagination__button + ' pointer-events-none' }
              key='pagination-skip-low'
              onClick={ () => updatePage(Math.round((startPosition) / 2)) }
            >
              &#8230;
            </button>
          );
        } else if (currentNumber === 3) {
          // Render 2nd page
          pages.push(
            <button
              type='button'
              className={ styling.pagination__button }
              key='pagination-2'
              onClick={ () => updatePage(2) }
            >
              2
            </button>
          );
        }
      }

      // Always render the active page and its surrounding visible range
      pages.push((() => {
        const number = currentNumber;
        return (
          <button
            type='button'
            className={
              number === props.currentPage
                ? `${ styling.pagination__button } ${ styling['pagination__button--active'] }`
                : styling.pagination__button
            }
            key={ `pagination-${ number }` }
            onClick={ () => updatePage(number) }
          >
            { number }
          </button>
        );
      })());

      if (pageCount === totalVisible) {
        if (currentNumber < totalPages - 2) {
          // Render dots ... after visible range
          pages.push(
            <button
              type='button'
              className={ styling.pagination__button + ' pointer-events-none' }
              key='pagination-skip-high'
              onClick={ () => updatePage(Math.round((endPosition + totalPages) / 2)) }
            >
              &#8230;
            </button>
          );
        } else if (currentNumber === totalPages - 2) {
          // Render page before last
          pages.push(
            <button
              type='button'
              className={ styling.pagination__button }
              key={ `pagination${ totalPages - 1 }` }
              onClick={ () => updatePage(totalPages - 1) }
            >
              { totalPages - 1 }
            </button>
          );
        }
      }

      pageNumber++;
      pageCount++;
    }

    // Render last page
    if (totalPages !== 1) {
      pages.push(
        <button
          type='button'
          className={
            totalPages === props.currentPage
              ? `${ styling.pagination__button } ${ styling['pagination__button--active'] }`
              : styling.pagination__button
          }
          key={ `pagination-${ totalPages }` }
          onClick={ () => updatePage(totalPages) }
        >
          { totalPages }
        </button>
      );
    }

    // Arrow right (next)
    pages.push(
      <button
        type='button'
        className={
          totalPages === props.currentPage
            ? `${ styling.pagination__chevron } ${ styling['pagination__chevron--disabled'] }`
            : `${ styling.pagination__chevron }`
        }
        key={ 'pagination-chevron-right' }
        onClick={ () => updatePage(props.currentPage + 1) }
      >
        <span className='mr-2'>Volgende</span>
        <Icon name={ 'ChevronRight' }  />
      </button>
    );

    return pages;
  };

  return (
    <>
      { totalPages > 1 && !isMobile &&
        <div
          className={ styling.pagination + ` ${ props.className ?? '' }` }
          ref={ paginationRef }
        >
          { createPages() }
        </div>
      }
      {
        totalPages > 1 && isMobile &&
        <div
          className={ styling.pagination + ` ${ props.className ?? '' }` }
          ref={ paginationRef }
        >
          <button
            type='button'
            className={
              1 === props.currentPage
                ? `${ styling.pagination__chevron } ${ styling['pagination__chevron--disabled'] }`
                : `${ styling.pagination__chevron }`
            }
            key={ 'pagination-chevron-left' }
            onClick={ () => updatePage(props.currentPage - 1) }
          >
            <Icon name={ 'ChevronLeft' }  />
            <span className='ml-2'>Vorige</span>
          </button>
          <div
            className={ 'text-body-md whitespace-nowrap' }
          >
            <span className={ 'text-body-md text-body-md--semibold text-primary ml-6' }>
              { props.currentPage }
            </span>
            <span className='mr-6'> / { totalPages }</span>
          </div>
          <button
            type='button'
            className={
              totalPages === props.currentPage
                ? `${ styling.pagination__chevron } ${ styling['pagination__chevron--disabled'] }`
                : `${ styling.pagination__chevron }`
            }
            key={ 'pagination-chevron-right' }
            onClick={ () => updatePage(props.currentPage + 1) }
          >
            <span className='mr-2'>Volgende</span>
            <Icon name={ 'ChevronRight' }  />
          </button>
        </div>
      }
    </>
  );
}
