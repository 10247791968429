const exitPreviewButtonStyle = `
  z-10
  inline-flex
  items-center
  font-normal
  font-default
  box-border
  leading-[1.2]
  group
  transition-all
  bg-grey-shade-0
  text-grey-shade-900
  shadow-[inset_0_0_0_1px_rgb(0,0,0)]
  border-grey-shade-900
  hover:bg-grey-shade-900
  hover:text-grey-shade-0
  text-[1rem]
  py-[.844rem]
  px-[1.375rem]
  fixed
  bottom-8
  right-10
  shadow-lg
`;

export default function PreviewButton({ slug, locale }) {
  return (
    <a
      href={ `/api/exit-preview?slug=${ slug }&locale=${ locale }` }
      className={ exitPreviewButtonStyle }
    >
      Exit preview
    </a>
  );
}
