import Grid from '@components/core/layout/Grid';
import Icon from '@core/Icon';
import { Fragment } from 'react';
import Link from 'next/link';

/* Helpers */
import { capitalizeFirstChar } from '@helpers/string';

const getLink = (slugs, index) => {
  let link = '';
  slugs.map((slug,i) => {
    if (i <= index) {
      link += (slug + '/');
    }
  });
  return link;
};

export default function Breadcrumbs( { slugs } ) {
  return (
    ( slugs.length > 0 &&
      <div className='bg-primary-50 pt-6'>
        <Grid>
          <div className='flex flex-wrap items-center col-span-12 text-body-md'>
            <Link href='/'>
              <Icon name='HomeOutlined' className='hover:text-primary' />
            </Link>
            {
              slugs.map((crumb, index) => {
                return (
                  <Fragment key={ index }>
                    <Icon name='ChevronRight' className={ slugs.length - 1 !== index ? 'w-4 h-4': 'text-primary w-4 h-4' } />
                    <Link href={ getLink(slugs, index) }className={ slugs.length - 1 !== index ? 'hover:text-primary': 'text-primary' } >
                      { capitalizeFirstChar(crumb) }
                    </Link>
                  </Fragment>
                );
              })
            }
          </div>
        </Grid>
      </div>
    )
  );
}
