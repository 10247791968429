import React from 'react';
import Icon from './core/Icon';
import { Image } from './core/Image';
import NextImage from 'next/image';

const renderLocationData = (location, productLookUp) => {
  if (location.sectionBox && location.sectionBox.length > 0) {
    const multiContactSectionIndex = location.sectionBox.findIndex((section) => section._type === 'multiContactSection');
    const productNames = location.sectionBox[multiContactSectionIndex].products.map((product) => product.productName);

    return (
      <React.Fragment key={ location.pageTitle }>
        <div className='flex items-center mb-2'>
          <Icon name='PlaceOutlined' className='!w-4 !h-4' />
          <span className='text-body-sm md:text-body-md'>
            { location.sectionBox[multiContactSectionIndex].products[0].contactDetails?.address },
            &nbsp;{ location.sectionBox[multiContactSectionIndex].products[0].contactDetails?.city }
            { location.zipcode?.distance
              ? <span className='text-shade-700'> &nbsp;- { parseFloat(location.zipcode?.distance).toFixed(1) + ' km' }</span>
              : <span></span>
            }
          </span>
        </div>
        <div className='grow'></div>
        <div className='flex flex-wrap'>
          { productNames.map((name, index) =>
            <div
              key={ index }
              className='text-body-sm px-2 py-1 border border-shade-200 rounded-2xl mr-2 whitespace-nowrap mt-2'>
              { productLookUp[name] }
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
};

export default function Card({ location, productLookUp, domain }) {
  return (
    <div className='relative h-full w-full flex flex-col hover:cursor-pointer hover:scale-105 hover:drop-shadow-xl transition-all'>
      <div className='mb-2 md:mb-4 relative rounded-2xl overflow-hidden w-full h-[193px] md:h-[196px] xl:h-[216px] bg-primary-50'>
        {
          location.cardImage?.image?.asset ?
            <Image
              sanityImage={ location.cardImage.image }
              layout='fill'
              objectFit='cover'
              sizes='(min-width: 1280px) 388px,(min-width: 768px) 660px, 558px'
            />
            :
            <NextImage
              src={ `/assets/fallback-${ domain }.svg` }
              fill={ true }
              className='object-scale-down'
              alt={ `thumbnail-of-${ location.pageTitle }` } />
        }
      </div>
      <div className='text-heading-xs md:text-heading-sm mb-2'>{ location.pageTitle }</div>
      { renderLocationData(location, productLookUp) }
    </div>
  );
}
