/* Services (CMS API calls) */
import { fetchPages } from 'services/page';
import { fetchSlug } from 'services/slug';
import { fetchNavigation } from '@services/navigation';
import { fetchFooter } from '@services/footer';
import { fetchLocations, fetchSingleLocationProducts } from '@services/location';
import { fetchCookie } from '@services/cookie';

/* Components */
import { NextSeo } from 'next-seo';
import SectionBox from '@components/SectionBox';
import HeaderBox from '@components/HeaderBox';
import Footer from '@components/Footer';
import PreviewButton from '@components/PreviewButton';
import PageLayout from '@components/core/layout/PageLayout';
import Navigation from '@components/Navigation';
import Overview from '@components/templates/Overview';
import Breadcrumbs from '@components/Breadcrumbs';

/* Helpers */
import { filterPreview } from '@helpers/preview';
import { getCanonical, getOpenGraphProp } from '@helpers/seo';

const themeDomains = [ 'kinderopvang', 'zorg', 'meander-internaten', 'corporate' ];

export async function getStaticPaths() {
  const pages = await fetchPages();

  const paths: {
    params: { slug: string[] },
    locale: string
  }[] = [];

  // Add pages paths
  pages.forEach((page) => {

    // Add nested paths
    const slug = [ `${ page.slug.current }` ];
    let currentElement = page;
    while (currentElement.parent && currentElement.parent.slug.current !== '/') {
      slug.unshift(currentElement.parent.slug.current);
      currentElement = currentElement.parent;
    }

    paths.push({
      params: { slug: slug.length == 1 && slug[0] === '/' ? [] : slug }, // Home '/' isn't a valid slug
      locale: page.__i18n_lang,
    });
  });

  return {
    paths,
    fallback: false,
  };
}

interface IStaticProps {
  params: {
    slug: string[]
  },
  locale: string,
  preview: boolean,
}

export async function getStaticProps({ params, locale, preview = false }: IStaticProps) {
  const slug = params.slug
    ? params.slug[params.slug.length - 1]
    : '/';

  let pageProps = params.slug?.length > 1
    ? await fetchSlug(slug, locale, preview, params.slug[params.slug.length - 2])
    : await fetchSlug(slug, locale, preview);

  pageProps = filterPreview(pageProps, params, preview);

  if (slug === 'locaties' && params.slug?.length > 1) {
    // Add locations data to page
    pageProps.locations = await fetchLocations(locale, preview, pageProps._id);
  }

  /* If headerSimple and we are at kinderopvang detail page get list of products */
  if (pageProps.headerBox) {
    const headerBoxIndex = pageProps.headerBox.findIndex((section) => section._type === 'headerSimple');
    if ( headerBoxIndex !== -1 && params.slug?.length === 3 && params.slug[0] === 'kinderopvang' && params.slug[1] === 'locaties') {
      pageProps.headerBox[headerBoxIndex].products = await fetchSingleLocationProducts(locale, preview, slug);
    }
  }

  const navigationQueryResult = filterPreview(await fetchNavigation(locale, preview),params, preview);
  const footerQueryResult = filterPreview(await fetchFooter(locale, preview),params, preview);
  const cookie = await fetchCookie(locale, preview);

  const theme = params.slug && themeDomains.includes(params.slug[0]) ? params.slug[0] : 'corporate';

  const notFound = Object.keys(pageProps).length === 0 || (Array.isArray(pageProps) && pageProps.length === 0);

  return {
    props: {
      // siteTitle,
      pageProps,
      locale,
      isInPreviewMode: preview,
      theme,
      navigationQueryResult,
      footerQueryResult,
      params,
      cookie,
    },
    notFound,
  };
}

export default function DynamicPathPage({ pageProps, locale, isInPreviewMode, theme, navigationQueryResult, footerQueryResult, params }) {

  const {
    seo,
    pageTitle,
    sectionBox,
    headerBox,
    locations,
  } = pageProps;

  // Last item has background?
  let backgroundColorFooter = 'bg-white';
  if (sectionBox && sectionBox[sectionBox.length -1]) {
    if (sectionBox[sectionBox.length -1]._type === 'component') {
      backgroundColorFooter = sectionBox[sectionBox.length -1].sections[0]?.sectionBackgroundColor ? 'bg-primary-50' : 'bg-white';
    } else {
      backgroundColorFooter = sectionBox[sectionBox.length -1].sectionBackgroundColor ? 'bg-primary-50' : 'bg-white';
    }
  }

  // First item has background?
  let backgroundColorHeader = 'bg-white';
  if (sectionBox && sectionBox[0]) {
    if (sectionBox[0]._type === 'component') {
      backgroundColorHeader = sectionBox[0].sections[0]?.sectionBackgroundColor ? 'bg-primary-50' : 'bg-white';
    } else {
      backgroundColorHeader = sectionBox[0].sectionBackgroundColor ? 'bg-primary-50' : 'bg-white';
    }
  }

  return (
    <>
      <NextSeo
        title={ seo?.seoTitle ?? pageTitle }
        description={ `${ seo?.description ?? '' }` }
        canonical={ `${ getCanonical(seo?.canonical, params.slug ) }` }
        noindex={ seo?.noindex || false }
        nofollow={ seo?.nofollow || false }
        { ...getOpenGraphProp(seo) }
      />
      <PageLayout theme={ theme }>
        {
          navigationQueryResult &&
          <Navigation { ...navigationQueryResult } theme={ theme } />
        }
        <Breadcrumbs slugs={ params.slug? params.slug : [ ]  } />
        <HeaderBox
          headers={ headerBox }
          locale={ locale }
          theme={ theme }
          backgroundColorHeader={ locations && locations.length > 0 ? 'bg-white' : backgroundColorHeader }
        />
        {
          locations && locations.length > 0 &&
          <Overview
            key={ theme }
            locations={ locations }
            domain={ theme }
          />
        }
        <SectionBox
          sections={ sectionBox }
          locale={ locale }
        />
        {
          footerQueryResult &&
          <Footer { ...footerQueryResult } theme={ theme } className={ backgroundColorFooter } />
        }
        {
          isInPreviewMode &&
          <PreviewButton slug={ params?.slug ? String(params.slug) : '/' } locale={ locale } />
        }
      </PageLayout>
    </>
  );
}
